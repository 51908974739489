body {
  font-family: "Noto Kufi Arabic", sans-serif;
  font-optical-sizing: auto;
  font-weight:weight;
  font-style: normal;
  font-size: 29px;
  background-color: #EDEDEE !important;
}
title{
  font-family: "Noto Kufi Arabic", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-size: 24px;
}
.navbar-dark .navbar-toggler, .navbar-light .navbar-toggler{

}
.navbar-brand{
  font-size: 22px !important;
}
.navbar-light .navbar-toggler-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}
.MarginTop{
  margin-top: 70px !important;
}

.card-text {

}
.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
  margin-left: 0% !important;
  
} 
.dropdown-menu.show{
  transform: translate3d(-0.222px, -10.8889px, 0px) !important;
}
.dropdown-menu[data-bs-popper]{
  top: 0px;
  width: 100% !important;
  font-size: 16px !important;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 40%;
    
  }
  
}
.dropdown-menu.show{
  transform: translate3d(-0.222px, 31.1111px, 0px) !important;
}

/* ServiceListPage.css */
.service-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-icon {
  color: #3B71CA;
  margin-bottom: 15px;
}

.service-title {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.search-form {
  width: 100%;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ced4da;
}
/*---------------- Mesaages --------------*/

.sender-name {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sender-name .profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message {
  margin-bottom: 10px;
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }

  
}
#about{
  margin-right: 20px !important;
}
#about h2::after{
  right :0;
 
}

#services {
 
}
#about .about-text li:before {
  content: "\2713";
  font-size: 20px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
  width: 6em !important;
    height: 3em !important;
}
.css-z7uhs0-MuiStepConnector-line{
  margin-top: 10px
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel{
  font-family: 200px !important;
  font-family: "Noto Kufi Arabic", sans-serif;
}

#fet{
  color: white;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    width: 60px;
    box-shadow: 2px 3px 19px;
    padding: 31px;
    border-radius: 100px;
}
#features i.fa{
  width: 130px;
  height: 130px !important;
  font-size: 50px;
}
.stepper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
 /* لون الخلفية */
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.step-number {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%); /* خلفية الدائرة */
  color: #fff; /* لون النص داخل الدائرة */
  border: 2px dashed #fff; /* حواف متقطعة */
  text-align: center;
  margin-bottom: 5px;
  font-size: 20px;
}

.step-description {
  text-align: center;
  font-size: 18px;
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 2px;
  height: 30px;
  background-color: white; /* لون الخط الواصل */
  transform: translateX(-270%) !important;
}

@media (min-width: 768px) {
  .stepper-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 100%;
    width: 100%;
    height: 2px;
    background-color: white; /* لون الخط الواصل */
    transform: translateY(0%) translateX(-200%)
  }
  
}
/*   Service Providers */

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-60%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  
}

.logos:after {
  right: 0;
 
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 70px;
  margin: 0 40px;
}

#contact .section-title h2::after{
 right: 0;
}

.btn{
  font-size: 16px !important;
}

.card-header:first-child{
  font-size: 18px;
}

.navbar-nav{
  font-size: 16px
}

nav a:hover{
  color:  #6372ff !important
}
nav a:focus{
  color:  #6372ff !important
}
nav a:active{
  color:  #6372ff !important
}
.navbar-nav .show>.nav-link, .navbar-nav .nav-link.active{
  color:  #6372ff !important
}

.form-control{
  font-size: 15px !important;
}
.form-label{
  font-size: 15px !important;
}
@media (min-width: 768px) {
  .navbar-nav{
    float: right;
  }
 
}

.service-title .card-title h5{
  font-size: 15px !important;
}

.card-title{
  font-size: 15px !important;
}

.badge {
  font-size: 11px !important;
    
}

/* Fetures */
.section-title h2 ,
 #services h2 ,
 #about h2{
  font-size: 28px !important;
}
#features h3 ,
#services h3,
#contact h3 {
  font-size: 22px !important;
}
#features p ,
#about li ,
#about p , 
#services p,
#contact .nav-link ,
#contact span
{
  font-size: 18px !important;
}

#contact .nav-link {
  color:#fff;
}
hr{
  height: 1px;
    width: 100%;
}

.typing {
  position: absolute;
  left: -7px;
    top: -9px;
}

/* -------------------------------- Print --------------------------------*/
@media print {
  .print-section {
    display: block !important; /* عرض العناصر المراد طباعتها */
  }

  body * {
    visibility: hidden; /* إخفاء كل العناصر في الصفحة */
  }
}

/* -------------------------------- Users --------------------------------*/
table td{
font-size: 16px;
}
table th{
  font-size: 18px;
}
@media (max-width: 590px) {
  table td{
    font-size: 14px !important;
    }
    table th{
      font-size: 12px !important;
    }
}

h6, .h6{
  font-size: 1rem !important;
}
h5, .h5{
  font-size: 1rem !important
}