body {
  font-family: "Noto Kufi Arabic", sans-serif !important;
  font-size: 15px !important;
}


.AboutSection{
  margin-top: 40px !important;

}

/* City Dropdown */
.dropdown-item{
  text-align: start !important;
}
.modal-header .btn-close {
  margin:0px !important
}
.offcanvas-header .btn-close{
  margin: 0px !important;
}
.modal-footer{
  justify-content: center
}